html {
    width: 100%;
    height: 100%;
}

.login {
    background-color: #575757;
    background-image: url("../../../public/images/white-orange-gradient.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: inline-table;
    position: fixed;

    button {
        margin: 15px 0;
        width: 100%;
        background-color: #f5831f;
        color: white;
    }

    button:hover {
        color: white;
    }

    label {
        color: black;
    }

    a {
        color: #f5831f;
    }

    .forget-password {
        text-decoration: underline;
        font-size: 12pt;
    }
}

.footer {
    background-color: black;
    align-content: center;
    z-index: 0;

    a {
        color: white;
        font-size: 12pt;
    }

    a:hover {
        text-decoration: underline;
    }
}

.login-logo {
    width: 210px;
    height: 80px;
    margin: 10px auto 30px;
    background-size: cover;
}

.login-container {
    position: relative;
    width: 400px;
    top: 80px;
    text-align: center;
    margin: 18px auto;
}

.optimal-user {
    color: #333;
    font-size: 15px;
    margin: 7px auto;
    font-family: Lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
    text-align: justify;
    width: 393px;
}

.ontellus-text {
    color: #FF7F00;
    padding-bottom: 15px;
}

.version-text {
    padding-top: 40px;
    vertical-align: middle;
    text-align: center;
}

.panel-body {
    padding: 15px 15px 0 15px;
}

.modal-close {
    font-size: 24px;
    opacity: .5;
}

.policy-text {
    padding-bottom: 15px;
    vertical-align: middle;
    text-align: center;
}

div.policy-text > a {
    color: #FF7F00;
    cursor: pointer;
}

.policy-scroll-body {
    height: 650px;
    overflow-y: auto;
}

.policy-body {
    margin: 20px;
    margin-bottom: 0px;
    text-align: justify;
}

ol.policy-section {
    font-weight: bold;
}

.logo {
    width: 10%;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    z-index: 10;
    position: relative;
}

.incorrect-cred {
    color: red;
    font-size: 15px;
    margin: -20px 0 -10px 0;
}

.correct-cred{
    margin: -20px 0 -10px 0;
    opacity: 0;
}
