.toast-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 10px;
    position: relative;
    width: 410px;
    height: 56px;
    background: #000000;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.toast-message~svg {
    fill: var(--prime-sex) !important;
}
.Toastify__close-button > svg {
  fill: var(--prime-sex) !important;
  height: 16px;
  width: 14px;
}