.search-result {
    .addresses {
        font-size: 14px;
        font-weight: 400;
        color: black;
        padding: 8px;

        .address-list {
            padding-right: 25px;
        }

        .address-card {
            border-radius: 8px;
            border: 0;
            background-color: white;
            padding: 18px;
            margin-bottom: 20px;
        }

        .text {
            font-size: 14px;
            line-height: 18px;
            align-self: center;
        }
    }
}
