$education-grey: #76787B;
$black: #2F3151;

.work {
    color: $black;
    margin-left: 0;

    .work-card {
        border-radius: 8px;
        border: 0;
        background-color: white;
        padding: 18px;
        margin-bottom: 20px;
        margin-right: 10px;
    }

    .work-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: -8px;
    }

    .work-data {
        font-size: 12px;
        font-weight: 400;
        line-height: 110%;
        margin-top: 5px;
        margin-bottom: -10px;

        img {
            margin: -4px 12px 32px 6px;
            width: 20px;
            height: 20px;
        }
    }

    .work-data-header {
        color: $education-grey;
    }
}