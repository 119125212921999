.contact-us-page {
    text-align: center;
    margin-top: 20px;

    .contact-us-wrapper {
        width: 80%;
        display: contents;

        .intertel-logo-img {
            width: 250px;
            height: auto;
            position: absolute;
            top: 25px;
            left: 25px;
        }

        .contact-us-title {
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 100px;
            color: #a19c9c;
        }

        .contact-us-section-title {
            font-size: 25px;
            font-weight: bold;
            color: black;
        }

        img {
            width: 100px;
            height: 100px;
        }

        .img-wrapper {
            align-content: center;
            display: block;
            margin-bottom: 20px;
        }

        .btn {
            box-shadow: 10px 10px 20px rgb(0 32 145 / 11%);
            border-radius: 10px;
            background-color: white;
            width: 110px;
        }

        .contact-us-data {
            margin-bottom: 10px;
        }

        .login-btn {
            text-decoration: underline;
            color: #f5831f;
            margin-top: 150px;
            width: min-content;
            height: min-content;
        }
    }
}
