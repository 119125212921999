﻿$orange: #f5831f;


input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.successful-register {
    height: 100%;

    .row {
        text-align: center;
        margin-bottom: 12px;
    }

    .next-btn {
        width: 130px;
        font-size: 13px;
        margin-bottom: -18px;
    }

    .congrats {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #1EDA47;
    }

    .info {
        font-size: 16px;
        line-height: 19px;
        color: black;
    }

    .detailed-info {
        font-size: 14px;
        line-height: 17px;
        color: #76787B;
        margin-top: 12px;
    }
}

.mandatory-symbol {
    color: red;
    font-size: 14px;
}

.modal-content {
    border-radius: 10px;
    border-color: white;
}

.cancel-modal-card {
    height: 190px;
    width: 100%;
    margin-left: -22px;
    margin-top: -7px;
}

.cancel-modal {
    align-items: baseline; 
}

.cancel-modal-header {
    align-items: center;
    padding-top: 22px;
    margin-left: -35px;
}

.cancel-modal-body {
    color: black;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: -20px;
    transform: translateY(10px);
}

.cancel-modal-btn-row {
    transform: translate(66px, -15px);
}

.next-btn, .previous-btn {
    font-size: 13px;
}

.next-btn {
    background-color: $orange;
    color: white;
    padding: 15px
}

.previous-btn {
    background-color: white;
    color: $orange;
    padding: 15px;
    border: $orange 1px solid;
    margin-right: 15px;
}

.next-btn:hover{
    color: white;
} 

.previous-btn:hover{
    color: $orange;
}

.close-modal-icon:hover {
    cursor: pointer;
}

.close-modal-icon {
    position: absolute;
    right: 0;
    background-color: #F6F6F6;
    border-radius: 5px;
    height: 23px;
    align-items: center;
    display: flex;
    color: black;
    font-size: 25px;
    padding: 8px 8px 12px 8px;
}

.cancel-modal-title {
    font-weight: 700;
    font-size: 20px;
    color: black;
    line-height: 24px;
}


.warning-modal-card {
    padding: 12px 8px 10px 29px;

    .warning-modal-body {
        color: black;
        font-size: 14px;
        line-height: 20px;
        color: black;
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .warning-modal-header {
        align-items: center;
    }

    .next-btn, .previous-btn {
        font-size: 13px;
    }

    .warning-modal-btn-row {
        transform: translate(52px, 10px);
    }
}



.registration {
    background-image: url("../../../public/images/gradient.png");
    position: absolute;
    width: 100%;
    top: 0;

    .form-control{
        font-size: 16px;
    }

    input[type=checkbox]:after {
        content: "✓";
        color: white;
        margin-top: -10px;
        display: block;
        font-size: 14px;
        font-weight: bold;
        transform: translate(1.25px, -3.9px);
        rotate: 3deg;
    }

    .row-style {
        margin: 25px 0 -20px -25px;
    }

    .invalid-feedback {
        margin-bottom: -22px;
    }

    .form-control {
        appearance: auto;
    }

    .error-message {
        color: red;
        font-size: 15px;
    }

    input {
        accent-color: $orange;
    }

    div[class*="col"] {
        padding: 0;
    }

    input {
        z-index: 1;
        position: relative;
    }

    .section-wrapper {
        background-color: white;
        border-radius: 20px;
        margin: 6% 15%;
        padding: 12px;
        padding: 25px 35px;

        .registration-title {
            span {
                font-weight: 700;
                font-size: 20px;
                line-height: 24.3px;
                color: black;
            }
        }

        .intro {
            font-size: 12px;
            line-height: 14.4px;
            color: #76787B;
        }

        .current-step {
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .section-title {
        font-size: 16px;
        line-height: 19px;
        color: #76787B;
        font-weight: 400;
    }

    .first-col-input {
        margin-right: 30px
    }

    hr {
        margin-top: 20px;
        color: gray;
        margin-left: 10px;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .cancel-btn {
        background-color: #DA1E28;
        color: white;
        padding: 15px
    }

    .privacy-policy--terms-and-conditions {
        position: relative;
        padding: 0;
        max-height: 400px;
        overflow: scroll;
        margin-bottom: -70px;
        font-size: 14px;

        .terms-title, .privacy-title, .height-50 {
            display: none;
        }
    }

    .p-30px {
        padding: 30px;
    }

    .collapseBtn {
        color: $orange;
        font-weight: bold;
        cursor: pointer;
    }

    input, select {
        border-radius: 5px;
        border: #e1e1e1 2px solid;
        width: 100%;
    }

    select {
        padding: 18px 0 0 5px !important;
    }

    input:hover, input:focus, select:hover, select:focus {
        border: solid 2px $orange;
    }

    input:hover, label:hover, select:hover {
        cursor: pointer;
    }

    input[type="text"]:disabled:hover {
        border-color: #e1e1e1;
        cursor: not-allowed;
    }

    .btn.disabled, .btn:disabled {
        pointer-events: all;
        cursor: not-allowed;
    }
}

.accept-terms {
    .input-mb{
        margin-bottom: 30px;
    }

    label{
        font-weight: bold;
    }
}

.account-info {

    .ml-n-25px {
        margin-left: -25px;
    }

    .section {
        margin-top: 35px;
        margin-bottom: -20px;
    }
}

.glba-dppa-options {
    .glba-dppa-intro {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: black;
        margin-left: -10px;
        margin-bottom: -10px;
    }

    label {
        font-size: 14px !important;
        line-height: 17px !important;
        color: black !important;
        margin-left: 10px !important;
    }

    .option-title {
        margin-top: 30px !important;
        margin-bottom: -10px !important;
    }

    .section-title {
        font-size: 16px;
        line-height: 19px;
        color: #76787B;
        font-weight: 400;
    }

    
}

input[type=radio]:after {
    content: "O";
    color: white;
    transform: translate(0.5px, -3.6px);
    margin-top: -10px;
    display: block;
    font-size: 14px;
    font-weight: bold;
}

input {
    accent-color: $orange;
}

input:not([disabled]){
    cursor: pointer;
}

td{
    width: fit-content;
}

.error-modal {
    align-items: baseline;
    background-color: #FCE9EA;
    width: 500px;
    height: 185px;
    border-radius: 8px;

    .warning-btn {
        background-color: #DA1E28;
        color: white;
        transform: translateX(275px);
    }

    .close-modal-icon {
        background-color: transparent;
        margin-right: 15px;
    }
}

.set-password-wrapper {
    height: 100%;

    .section-wrapper {
        width: 550px;
        transform: translateX(330px);
        color: black;
    }

    .btn {
        margin-bottom: 15px;
        width: 100%;
    }

    input {
        margin: 15px 0;
    }

    .section-wrapper {
        padding: 25px 50px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 0;
    }
}

.registration-error-modal {
    height: auto;

    .warning-btn{
        margin-bottom: 15px;
    }
}
