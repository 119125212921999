.form-div {
    background-color: var(--prime-sex);
    border-radius: 25px
}

.form-logo {
    width: 138px;
    height: 48px
}

.register-title {
    width: 90px;
    margin-top: 24px;
    height: 29px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--prime-eight)
}

.register-tip {
    width: 159px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: var(--prime-seven)
}

.login-link {
    width: 34px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: var(--prime-three);
}

.main-form {
    padding: 24px, 0px !important;
    margin-top: 24px !important;
}

.form-label {
    width: fit-content;
    height: 15px;
    font-weight: 400 !important;
    line-height: 15px !important;
    color: gray !important;
}

.form-select {
    font-size: 18px;
    width: 100%;
    height: 60px;
    border: 1px solid rgba(0, 40, 78, 0.15);
    border-radius: 8px;
    padding: 0 20px
}

.btn-row {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 48px
}

.submit-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: 48px;
    background: var(--prime-three);
    border-radius: 4px;
    color: white;
}

.submit-btn:hover{
    color: white;
}


@media screen and (min-width:768px) {
    .submit-btn {
        width: auto;
    }
}

.forget-password-title {
    width: 173px;
    margin-top: 24px;
    height: 29px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--prime-eight)
}

.forget-password-tip {
    width: 504px;
    height: 38px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--prime-seven)
}

.reset-password-title {
    width: 250px;
    margin-top: 24px;
    height: 29px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--prime-eight)
}

.reset-password-tip {
    width: 504px;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--prime-seven)
}

.hint-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 334px 0px 0px;
    width: 504px;
    height: 34px;
}

.hint-data {
    width: 170px;
    height: 15px;
    font-family: var(--font-main);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--prime-seven)
}

.password-checklist {
    font-size: 14px;
    line-height: 10px;
    font-weight: normal;
    margin-top:-15px;
}


.password-checklist svg {
    width: 10px ;
    height: 10px ;
}

.order1 {
  order: 0;
}


@media screen and (max-width:992px) {
    .order1 {
        order: 2;
      }
}

.forget-password-updates {
    margin: unset !important;

    .forget-password-title {
        display: inline;
    }

    p {
        line-height: 30px;
        margin-bottom: 40px;
    }

    .form-control:not(#email) {
        display: inline;
        color: white;
        background-color: #f5831f;
    }

    .form-control {
        display: inline;
    }

    .invalid-feedback {
        margin-bottom: -45px;
    }

}