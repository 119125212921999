
.output-content-div {
    margin-top:30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    flex: none;
    order: 2;
    flex-grow: 1;
}

.output-tab-content-div {
    box-sizing: border-box;
    overflow: hidden;
    /* Auto layout */

    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 10px;
    gap: 16px;

    width: 70%;

    /* Base / White */

    background: #FFFFFF;
    /* Base/Stroke */

    border: 1px solid #E1E1E1;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
 @media screen and (max-width:1200px) {
    .output-main-div {
        flex-direction: column;
        padding: 0px;
        gap: 24px;
        flex-grow: 0;
    }
    .output-tab-content-div {
        overflow: auto;
        display: flex;
        flex-direction: column;
    
        width: 100%;
        height: auto;
        /* Base / White */
        overflow-y: auto;
        background: #FFFFFF;
        align-self: auto;
    }
    .output-content-div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }
    .output-tabs-div  {
        flex-basis: 100%;
        overflow-y: scroll;
    }
 }

@media screen and (min-width:1200px) {
    .output-tabs-div {
        position: sticky;
        top: 86px;
    }
    .output-main-info-div {
        width: 342px;
    }
} 

.output-tabs-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 1px;
    gap: 24px;
    flex-basis: 28%;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    height: fit-content;
}

.output-main-info-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    align-content: center;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.output-picture {
    width: 150px;
    height: 150px;

    // background: url(.svg);
    /* Base/Stroke */

    border-radius: 50%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.output-name-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 0px;
    width: 230px;
    height: 45px;
    flex: none;
    order: 1;
    flex-grow: 1;
}

.output-name {
    height: 24px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.output-age {
    height: 17px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #76787B;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.output-header-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 24px;
    width: 100%;
    height: 56px;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.output-header-text {
    height: 85px;
    margin-left: -15px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #76787B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.output-request-details-div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 127px;
    height: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.output-request-details-text {
    height: 15px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #F5831F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.output-request-details-icon {
    width: 16px;
    height: 16px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.export-div {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 20px;
    gap: 16px;
    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: center;
    flex-grow: 0;
}

.export-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.output-tab-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;

    width: 100%;
    height: fit-content;


    /* Inside auto layout */

    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
}

.output-tab-item {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
    width: 100%;
    height: 32px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.list-group.list-group-flush.account-settings-links.nav {
    width: 100%;
}

.output-tab-item .active {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    width: 100%;
    height: 32px;

    /* Grey / LightGrey */

    background: #F6F6F6;
    /* Primary/Orange */

    border-left: 2px solid #F5831F;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.output-sub-tab-item {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
    width: 100%;
    height: 32px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.output-sub-tab-item.sub-active {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    width: 100%;
    height: 32px;

    /* Grey / LightGrey */

    background: #F6F6F6;
    /* Primary/Orange */

    border-left: 2px solid #F5831F;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.output-tab-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 274px;
    height: 32px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.output-tab-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 16px;
    gap: 24px;
    width: 100%;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.output-tab-icon {
    width: 18px;
    height: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.output-tab-content-icon {
    display: inline;
    width: 18px;
    margin-right: 5px;
    height: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
    transform: translateY(3px);
}


.hyperimg {
    display: inline;
    width: 50px;
    margin-right: 5px;
    height: 35px;
    margin-top: -9px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.output-tab-content-img {
    width: 50px;
    height: 50px;
    vertical-align: middle !important;
    margin-right: 5px;
   border-radius: 50%;;
} 

.output-tab-text-div {
    flex-direction: row;
    align-items: center;
    padding: 0px;
    height: 18px;
    flex: none;
    order: 1;
    flex-grow: 1;
}

.output-tab-text {
    height: 18px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.address-content {
    margin-bottom: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 34px;
    gap: 16px;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.address-inner-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 16px;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    box-shadow: 0px 0px 12px -4px rgb(0 0 0 / 20%);
}

.social-inner-content {
    margin-top: 30px;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 2px 16px;
    width: 100%;
    height: fit-content;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
}

.address-text {
    word-break: break-all;
    height: fit-content;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.16px;
    color: #000000;
    order: 0;
    flex-grow: 1;
    margin: 0;
}

.education-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 11px 16px;
    gap: 8px;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.education-inner-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: fit-content;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.education-inner-text-div-card-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    height: fit-content;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.education-inner-title-card-body {
    height: 18px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.education-inner-text-card-body {
    height: fit-content;
    word-break: break-all;
    width: 70%;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 1;
    align-self: baseline;
    display: block;
}

.education-box-body {
    margin-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    height: fit-content;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-shadow: 0px 0px 12px -4px rgb(0 0 0 / 20%);
    margin: 22px 36px;
}

.social-box-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 16px 16px;
    gap: 16px;
    width: 328px;
    height: 545px;
    background: #FBFBFB;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-box-header-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 16px 11px;
    gap: 16px;
    isolation: isolate;
    width: 296px;
    height: 179px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.social-box-header-inner-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 264px;
    height: 80px;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
}

.social-box-header-picture {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    border: 1px solid #E1E1E1;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-box-header-inner-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 168px;
    height: 80px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.social-box-header-social-icon-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: absolute;
    width: 48px;
    height: 48px;
    left: 8px;
    top: -24px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 2;
}

.social-box-header-social-icon {
    width: 32px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-btn-group-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    width: 100%;
    height: 48px;


    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.social-btn {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px;
    width: 136px;
    height: 48px;
    background: #DE7112;
    border: 1px solid #F5831F;
    border-radius: 4px;

    flex: none;
    order: 0;
    flex-grow: 1;
}

.social-btn-only {
    width: 102px;
    height: 18px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-btn-div {
    display: flex;
    flex-direction: row;
    padding: 13px 14px;
    gap: 32px;
    width: 132px;
    height: 44px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.social-btn-div-live-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 62px 13px 14px !important;
    width: 178px !important;
    height: 44px !important;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-btn-live {
    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-btn-only-div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;

    width: 136px;
    height: 48px;

    /* Primary/Orange */

    background: #F5831F;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;
}

.social-btn-text {
    width: 55px;
    height: 18px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-btn-icon {
    width: 16px;
    height: 16px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.social-card-div {
    margin-top: 10px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px -4px rgb(0 0 0 / 20%);
    border-radius: 10px;
    display: flex;
    padding: 10px;
    align-items: center;
    padding: 24px;
    margin: 36px;
}

.social-tab-icon-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 16px;
    gap: 24px;
    width: 48px;
    height: 32px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.social-tab-icon {
    width: 16px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.social-output-icon-div {
    border-radius: 100%;
    position: absolute;
    left: 12px;
    top: -20px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 2;
    border-radius: 21px;
    background-color: white;
    border: 1px solid #80808021;
    height: 45px;
}

.social-card-image{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: start;
    justify-content: start;
}

.social-output-icon {
    margin-right: 5px;
    width: 32px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.social-img {
    width: 120px;
    height: 120px;
    margin: 15px;
}

.search-result-left-menu-item {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    align-content: center;
    padding: 7px 16px;
    gap: 24px;
    display: flex;
    cursor: pointer;
    width: 100%;

    label {
        cursor: pointer;
    }
}

.search-result-address {
    box-shadow: 0px 0px 12px -4px rgb(0 0 0 / 20%);
    border-radius: 10px;
    display: flex;
    align-items: baseline;
    padding: 12px;
    margin: 15px 36px;
}

.search-result-section-title {
    height: fit-content;
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
    padding: 24px;
    display: flex;
    gap: 24px;
    color: orange;
    align-items: center;
}

.search-result-section-hr {
    margin: 8px 18px;
    opacity: 0.5;
    color: #80808066;
    width: 100%;
}

.social-card-body{
    width: 100%;
}

.search-result-scroll-link{
    display: contents;
    cursor: pointer;

    &:hover{
        color: black;
    }
}

.theme-inner-banner {
    padding: 150px 0 115px;
    height: 100%;
    width: 100%;
    display: inline-table;
}

.breaches {

    table {
        box-shadow: 0px 0px 12px -4px rgb(0 0 0 / 20%);
        margin-left: 130px;
        margin-top: -30px;
    }

    .table-header {
        background-color: #ffa5001c;
    }

    td {
        padding: 5px 5px 5px 10px
    }

    .title {
        font-size: 14px;
        font-weight: 700;
        padding: 12px 0px 17px 18px;
    }
}

.identifiers {
    position: absolute;
    right: 10px;
    font-size: 8pt;
    font-weight: 700;
    color: gray;
    top: 0
}

.generic-search-button {

    background-color: #f5831f;
    margin: 8px 0;

    a {
        color: white;
    }
}

.generic-search-button a:hover {
    color: white;
}

body, #root, .main-page-wrapper {
    height: 100%;
    letter-spacing: 0.5px;
}