﻿
$orange: #f5831f;

.username-link, .username-link:hover {
    text-decoration: underline;
    color: $orange;
    cursor: pointer;
}

.link-color{
    color: $orange;
}

td {
    vertical-align: middle;
}

.account-request-btns {
    justify-content: end;

    button {
        height: 40px;
        width: 100px;
        padding-top: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

.admin-page-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: black;
}

.approve-account-modal {
    margin-right: 100px;

    p {
        margin-right: 22px;
    }
}

.deny-account-modal {

    p {
        margin-right: 22px;
    }
}

.approve-account-modal-card {
    height: 183px;
    margin-left: 12px;

    .modal-btn-row {
        transform: translate(263px, 24px);
    }

    .approve-modal-header {
        align-items: center;
        padding-top: 10px;
        margin-left: -30px;
    }
}

.deny-account-modal-card {
    height: 380px;
    margin-left: 10px;

    .row-title {
        padding: 0;
        font-size: 12px;
        line-height: 15px;
        color: black;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .modal-btn-row {
        transform: translate(263px, 24px);
    }

    .approve-modal-header {
        align-items: center;
        padding-top: 10px;
        margin-left: -30px;
    }

    .cancel-modal-title {
        padding-left: 5px;
    }

    .deny-modal-btn-row {
        transform: translate(238px, 22px);
    }

    textarea {
        width: 460px;
    }
}

.user-details-modal-card {
    align-items: baseline;
    height: 100%;
    padding-bottom: 10px;

    .modal-title {
        margin-left: -22px;
        color: black;
        font-weight: 700;
    }
}

.account-info-details {
    margin-left: 35px;

    .admin-page-title{
        margin-bottom: 10px;
    }

    .cancel-modal-header {
        padding-bottom: 10px;
    }

    .header-section {
        margin-top: 10px;

        .section-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
        }
    }

    .info-section {
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;

        .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 15px;
            color: black;
        }

        .value {
            font-size: 14px;
            font-weight: 400;
            line-height: 15px;
            color: #76787B;
        }

        .row {
            padding: 0;
        }
    }
}

.user-info {

    .section-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .header {
        font-size: 24px;
        font-weight: 700;
        color: black;
        margin: 10px 0px;
    }

    .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        color: black;
        margin: 10px 0px;
    }

    .value {
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: #76787B;
        margin: 10px 0px 20px 0px;
    }

    .invalid-feedback {
        margin-bottom: -22px;
    }

    .form-control {
        appearance: auto;
    }

    select {
        padding: 18px 0 0 5px !important;
    }

    .account-request-section {
        background-color: transparent;
        box-shadow: none;
    }

    .header-section {
        padding: 0;
    }

    .titile-row {
        margin-top: -4px;
        position: absolute;
        margin-left: -20px;

        .title {
            font-weight: normal;
            color: gray;
        }

        .col-md-6 {
            height: 0;
        }
    }

    .data-row {
        margin-left: -32px;
    }

    .glba-dppa-intro {
        display: none;
    }

    .save-btn {
        text-align: end;
    }

    input, select {
        cursor: pointer;
    }

    input:hover, input:focus, select:hover, select:focus {
        border: solid 2px $orange;
    }

    input[type="text"]:disabled:hover {
        border: #e1e1e1 1px solid;
        cursor: not-allowed;
    }
}

.titile-row {
    margin-bottom: 2px;
    margin-top: 8px;
}

.data-row{
    margin-bottom: 8px;
}

.deny-modal-btn-row {
    transform: translate(208px, 24px);
}

.all-users-filters {
    background-color: rgba(245, 131, 31, 0.1019607843);
    padding: 16px 24px;
    justify-content: center;
    margin-top: 20px;
    border-radius: 12px;
    width: 100%;
    margin-left: 0px;

    .btn {
        width: 100px;
        margin-left: auto;
        margin-right: 0;
        display: block;
        margin-top: 15px;
    }

    .form-control {
        appearance: auto;
        font-size: 16px;
        padding: 10px;
    }
}

.account-request-section {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .invalid-feedback {
        font-weight: normal;
        margin-bottom: -15px;
    }
}

.no-matching-company-notification{
    font-size: 14px;
    color: black;
    font-weight: bolder;
    line-height: 15px;
    margin: 25px 5px;
    a {    
    font-weight: normal;
    font-size: 10px;
    color: #f5831f;
    text-decoration: underline;
    margin: 0px 10px;
    white-space: nowrap;
    }
}

.account-request-section--company {
    height: 159px;
}

.company-select {
    width: 220px;
    padding: 5px;
    border-radius: 5px;
    border: 1px lightgray solid;
    margin-top: 5px;
    appearance: auto;
}

.add-update-company-container {
    .section-title {
        font-weight: 400;
    }

    .section {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .form-check-input:checked {
        background-color: #f5831f;
        border-color: #f5831f;
    }

    .bi-trash::before {
        cursor: pointer;
    }

    .next-btn {
        width: 80px
    }

    .save-btn-row {
        display: flex;
        justify-content: end;
        padding-right: 35px;
    }

    .admin-page-title {
        display: contents;
    }

    .invalid-feedback {
        margin-bottom: -22px;
    }
}

.pending-registration-container{
    tr{
        height: 60px;
    }
}

.user-info {
    .inactive-account-reason {
        font-size: 14px;
        font-weight: normal;
        color: gray;
        margin-right: 10px;
        display: inline-grid;
        line-height: 15px;
        align-self: center;
    }

    .account-status {
        font-weight: normal;
        font-size: 16px;
        padding: 5px 15px;
        border-radius: 30px;
        color: white;
        cursor: pointer;
    }

    .active-account-status {
        background-color: #18e318f5;
    }

    .inactive-account-status {
        background-color: red;
    }

    .details-img {
        cursor: pointer;
    }
}

.change-account-status-modal-card {
    height: 222px;
    font-size: 14px;
    color: black;
    align-items: baseline;
    margin-left: 30px;

    .status-name {
        color: black;
        padding-left: 5px;
    }

    .change-account-status-modal-header {
        margin-top: 10px;
    }

    .close-modal-icon {
        margin-right: 22px;
    }

    .deny-modal-btn-row {
        transform: translate(316px, 24px);
    }

    textarea {
        width: 460px;
        height: 40px;
    }
}

.deactivate {
    height: 262px;

    .deny-modal-btn-row {
        transform: translate(307px, 24px);
    }
}

.dropdown-menu {
    top: -214px;
}

.same-as-account-poc {

    label {
        color: red !important;
        font-size: 14px !important;
    }
}

.account-request-title{
    font-size: 14px;
    color: black;
    font-weight: bolder;
}

.company-address{
    width: 200px;
    display: block;
}

.account-history{
    margin-top: 30px;
    color: black;
    th{
        color: black;
        font-weight: bold;
    }
    tr{
        height: 60px;
    }
    .btn{
        color: white;
        background-color: black;
    }
}

.account-history-modal-title{
    color: black;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.account-history-modal{
    max-width: 900px;
    .account-history-detail-modal-body {
        padding: 10px;
        color: black;
        font-size: 14px;


        .admin-response{
            font-size: 18px;
            font-weight: bold;
        }
        .deny{
            color: red;
        }
        .approve{
            color: green;
        }

        th{
            color: black;
            font-weight: bold;
        }

    }
    .btn{
        color: white;
        background-color: black;
    }
}

.show-btn {
    width: 125px;
    margin-right: 20px;
}

.status-toggle {
    display: inline-block;

    .form-check-input:checked {
        background-color: $orange;
        border-color: $orange;
    }

    input {
        cursor: pointer;
    }
}

.admin-page-title{
    a{
        cursor: pointer;
    }
}

.authorized-manager {
    
    input[type=checkbox]:after {
        content: "✓";
        color: white;
        margin-top: -10px;
        display: block;
        font-size: 14px;
        font-weight: bold;
        transform: translate(1.25px, -3.9px);
        rotate: 3deg;
    }

    input{
        margin-right: 10px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.authorized-manager-warning-modal {
    height: auto;

    .warning-modal-btn-row {
        transform: translate(64px, -4px);
    }

    .close-modal-icon{
        margin-right: -5px;
    }
}

.confirm-deletion-modal-card {
    margin-left: 50px;

    .modal-btn-row {
        transform: translate(300px, 38px);
    }

    .approve-modal-header {
        align-items: center;
        padding-top: 10px;
        margin-left: -30px;
    }

    .close-modal-icon {
        margin-right: 45px;
    }
}

.user-search-requests-details-modal {
    .modal-content{
        padding: 15px;
        }
}

.readonly-property{
    color: darkgrey;
    font-size: 16px;
}
