.privacy-policy--terms-and-conditions {
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    color: black;
    padding: 80px;
    font-size: 16pt;
    line-height: 20px;
    font-family: sans-serif;

    a {
        color: mediumblue;
    }

    .padding-left-65 {
        padding-left: 65px;
    }

    .padding-left-130 {
        padding-left: 130px;
    }

    .padding-left-50 {
        padding-left: 50px;
    }

    .padding-left-80 {
        padding-left: 80px;
    }

    .terms-title {
        color: #f5831f;
        font-size: 25pt;
    }

    .height-50 {
        height: 50px;
    }
}

.color-grey{
    color: grey;
}
