$dark-grey: #76787B;
$black: #212B36;
$light-orange: #fff5ed;
$orange: #F5831F;


.social-results {
    line-height: 110%;
    line-break: anywhere;

    .social-results-container {
        .social-result-card {
            break-inside: avoid;
            border-radius: 8px;
            border: 0;
            background-color: white;
            padding: 18px;
            margin: 0px 0px 20px 0px;
        }
    }

    .profile-pic {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    .social-small-result-img {
        height: 50px;
        width: 50px;
        margin: 2px;
        object-fit: cover;
    }

    .platform {
        border-radius: 500px;
        background-color: $light-orange;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Roboto';
        color: black;
    }

    .social-nav{
        position: sticky;
        top: 0;
        z-index: 0;
    }


    .facebook-search {
        width: fit-content !important;
        padding: 15px;
        line-break: auto;
    }

    .TikTok {
        background-color: rgba(0, 242, 234, 0.1);
    }

    .Facebook {
        background-color: rgba(22, 80, 229, 0.1);
        color: rgba(22, 80, 229, 1);
    }

    .Facebook-Gaming {
        background-color: rgba(22, 80, 229, 0.1);
        color: rgba(22, 80, 229, 1);
    }

    .Twitter {
        background-color: rgba(29, 161, 241, 0.1);
        color: rgba(29, 161, 241, 1);
    }

    .Pinterest {
        background-color: rgba(190, 3, 22, 0.1);
        color: rgba(190, 3, 22, 1);
    }

    .Instagram {
        background-color: rgba(200, 55, 171, 0.1);
        color: rgba(235, 74, 103, 1);
    }

    .SoundCloud {
        background-color: rgba(247, 135, 32, 0.1);
        color: rgba(247, 135, 32, 1);
    }

    .Paypal {
        background-color: rgba(39, 144, 195, 0.2);
        color: rgba(22, 80, 229, 1);
    }

    .AskFM {
        background-color: rgba(238, 17, 68, 0.1);
        color: rgba(238, 17, 68, 1);
    }

    .Youtube {
        background-color: rgba(255, 0, 0, 0.1);
        color: rgba(255, 0, 0, 1);
    }


    .platform-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline;
    }

    .social-identifiers-header {
        font-size: 12px;
        color: $black;
        font-weight: 500;
    }

    .social-identifiers {
        font-size: 10px;
        color: $dark-grey;
        font-weight: 400;
    }

    .social-result-name {
        font-size: 14px;
        color: $black;
        font-weight: 600;
    }

    .social-result-username {
        font-size: 12px;
        color: $dark-grey;
        font-weight: 400;
    }

    .social-med-header {
        font-size: 12px;
        color: $dark-grey;
        font-weight: 400;
    }

    .social-med-value {
        font-size: 14px;
        color: $black;
        font-weight: 500;
    }

    .social-large-header {
        font-size: 14px;
        color: $black;
        font-weight: 500;
    }

    .social-large-value {
        font-size: 10px;
        color: $dark-grey;
        font-weight: 400;
    }

    .social-num-header {
        font-size: 12px;
        color: $dark-grey;
        font-weight: 400;
    }

    .social-num-value {
        font-size: 16px;
        color: $black;
        font-weight: 600;
    }

    .social-short-header {
        font-size: 12px;
        color: $dark-grey;
        font-weight: 400;
    }

    .social-short-value {
        font-size: 12px;
        color: $black;
        font-weight: 500;
        margin-bottom: 7px;
    }

    .social-view-button {
        font-size: 13px;
        color: $orange;
        background-color: $light-orange;
        border-radius: 500px;
        font-weight: 500;
        width: 100%;
        border-style: none;

        svg {
            path {
                fill: $orange;
            }
        }
    }



    .social-result-no-data {
        font-size: 14px;
        color: $black;
        font-weight: 600;
    }

    a {
        padding: 0 0 6px 0;
    }

    .social-media-filter {
        justify-content: end;
        display: flex;
        margin-right: -72px;

        button {
            background-color: #f8f9fa !important;
            border: 1px solid #0000003B;
            margin-right: 45px;
            box-shadow: none !important;
            border-color: #0000003B !important;
        }

        .show-title {
            color: black;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        .selected-option {
            color: #f5831f;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            width: 120px;
            display: inline-flex;
            justify-content: flex-start;
        }

        img {
            display: inline;
            margin-right: 10px;
        }

        .dropdown-menu {
            background-color: #f6f6f6;
            border: none;
            margin-top: -2px;
        }

        a {
            padding: 12px 100px 16px 22px;
            font-size: 17px;
            font-weight: 400;
        }

        a:hover, a:focus {
            background-color: #f6f6f6;
            color: #76787b;
        }

        .show {
            box-shadow: 0px 10px 20px #e9ecef;
            z-index: 1;
        }

        .dropdown-toggle::after {
            color: gray;
        }

        .dropdown-toggle.show::after {
            color: gray;
            rotate: 180deg;
        }
    }

    .platform-filter {

        .menu {
            position: absolute;
            background-color: white;
            z-index: 1;
            border-radius: 10px;
            padding: 12px;
            transform: translateX(-165px);
        }

        .menu-item {
            padding: 4px 10px;
            font-size: 16px;
            line-height: 24px;
            color: black;
        }

        label {
            font-weight: 500;
        }

        label:hover, input:hover {
            cursor: pointer;
        }

        input {
            margin-right: 8px;
            accent-color: #F5831F;
        }

        input[type='checkbox']:checked:after {
            content: '\2713';
            color: white;
            transform: translate(2.5px, 4.4px);
            margin-top: -10px;
            display: block;
            font-size: 13px;
            font-weight: bold;
            rotate: 8deg;
        }

        .search input {
            font-size: 14px;
            line-height: 18px;
            color: gray;
            padding: 11px;
            border: 1px solid #dee2e6;
            border-radius: 8px;
            width: 240px;
            margin-bottom: 12px;
            margin-right: 0;
        }

        .menu-items {
            height: 220px;
            overflow: auto;
        }

        .filter-header {
            color: #F5831F;
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
        }

        .filter-header div:hover {
            cursor: pointer;
        }

        .cancel-btn {
            border: 1px solid #76787B;
            padding: 4px 10px;
            border-radius: 6px;
            font-size: 13px;
            color: gray;
        }

        .apply-btn {
            padding: 4px 10px;
            border-radius: 6px;
            font-size: 13px;
            color: white;
            background-color: #F5831F;
            margin-left: -4px;
        }

        .filter-icon {
            width: 24px;
            height: 37px;
            display: inline;
            margin: 0 10px 0 15px;
        }

        .filter-btn {
            background-color: #F5831F;
            color: white;
            border-radius: 6px;
            font-size: 16px;
            width: 145px;
            height: 40px;
            display: inline-flex;
            align-items: center;
        }

        .filter-count {
            margin: 0 20px 0 15px;
            background-color: white;
            color: #F5831F;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }
}

.form-result-title {
    font-size: 16px;
    color: grey;
}

.form-result-toggle {
    .form-check-input:checked {
        background-color: #F5831F;
        border-color: #F5831F;
    }
}

.align-self-center {
    align-self: center;
}

.user-social-profile-add-modal{
    input{
        margin: 5px;
    }
}
