$dark-grey: #76787B;
$orange: #F5831F;
$background-grey: #F9F9F9 ;


.search-result {

    .bi-trash {
        margin-left: 10px;
        color: #f62020;
        font-size: 20px;
        cursor: pointer;
        margin-top: -4px;
    }

    .emails {
        .google-map {
            justify-content: center !important;
            display: flex;
        }

        img {
            display: inline;
            margin-right: 12px;
        }

        .title {
            color: $dark-grey;
            font-weight: 400;
            font-size: 12px;
        }

        .map-title {
            align-items: center;
            display: inline-flex;
            margin-top: -5px;
        }

        .id-title {
            margin-top: -2px;
        }

        .email-card {
            border-radius: 8px;
            border: 0;
            background-color: white;
            padding: 18px;
            margin-bottom: 20px;
            width: 100%;
        }

        .image {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            margin-right: 12px;
            display: inline;
        }

        .header-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: black;
        }

        .val {
            font-size: 14px;
            line-height: 18px;
            color: black;
            align-self: center;
        }

        .data-row {
            margin-bottom: 15px;
        }

        .icon {
            display: flex;
            justify-content: center;
            padding-right: 0;
        }

        .map-icon {
            width: 30px;
        }

        .view {
            color: $orange;
            text-decoration: underline;
        }

        button {
            background-color: $background-grey;
            border: none;
            padding: 8px;
        }

        .accordion-body {
            background-color: $background-grey;
        }

        button:hover {
            box-shadow: none;
        }

        button:focus {
            box-shadow: none;
        }

        .breaches-title {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: $dark-grey;
        }

        .img-box {
            svg {
                transform: translate3d(6px, 3px, 0);
            }
        }

        .height-35 {
            height: 35px;
        }

        hr {
            margin-bottom: 5px;
        }

        .connected-sites {
            width: 70%;
            line-break: anywhere;
        }

        .accordion {
            padding: 0;
        }

        td {
            font-size: 13px;
            line-height: 30px;
            color: black;
        }

        .table-header {
            color: $dark-grey;
        }

        .accordion-item {
            border: none;
        }

        .accordion-button:not(.collapsed) {
            color: $orange !important;
        }

        .accordion-button::after, .accordion-button:not(.collapsed)::after {
            background-image: url("../../../../public/images/orange-down-arrow.svg");
        }

        .accordion-button:not(.collapsed)::after {
            transform: rotate(-180deg);
        }

        .d-table {
            display: table;
        }

        .d-table-cell {
            display: table-cell;
        }

        .name {
            margin-top: 6px;
        }

        .breaches-not-available {
            margin: 5px 0 5px 115px
        }

        .gaia-val {
            margin-top: -3px;
        }

        a {
            padding: 0;
            margin: 16px 0;
        }
    }

}

.remove-email-modal-card {
    height: 222px;
    margin-left: 50px;
    width: 540px;

    .email-modal-header {
        margin-left: -32px;
        margin-top: 10px;
    }

    .close-modal-icon {
        right: 110px;
        top: 18px;
    }

    .modal-btn-row {
        position: absolute;
        right: 120px;
        bottom: 15px;
    }
}
