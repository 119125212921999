.header-tr {
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 16px;
    gap: 24px;
    width: 1080px;
    height: 40px;
    background: #F5831F;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #E1E1E1;
    border-radius: 8px 8px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;

    th.sortable{
        cursor: pointer;
    }
}

#pageDropDown .caret{
    display: none;
}


.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}


.body-tr {
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 16px;
    gap: 24px;
    width: 1080px;
    height: 40px;
    background: #FFFFFF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E1E1E1;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.table-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    position: absolute;
    width: 1080px;
    height: 898px;
    left: 180px;
    top: 100px;
}

.table-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 1080px;
    height: 720px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

th {
    height: 18px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    color: white;
    flex: none;
    order: 3;
    flex-grow: 0;
}

td {
    width: 236px;
    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #76787B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.actions-td {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 236px;
    height: 16px;
    flex: none;
    order: 3;
    flex-grow: 0;
    width: fit-content;
}

.pagination.react-bootstrap-table-page-btns-ul {
    float: right !important;
}

.page-item.active .page-link {
    color: #76787B;
    background-color: var(--prime-three);
    border-color: var(--prime-three);
}

.page-item .page-link {
    color: #76787B;
    background-color: #FFFFFF;
    border-color: #dee2e6;
}

.table-parent-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 1080px;
    height: 720px;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}

.table-page-title {
    width: 60%;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.add-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px;
    width: fit-content;
    height: 48px;
    background: #F5831F;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-align: center;
}
.react-bootstrap-table-pagination-total{
    margin-left: 10px;
}
.add-btn-content {
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 5px;
}

.add-btn-text {
    width: fit-content;
    height: 18px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-right: 5px;
    /* identical to box height, or 129% */


    /* Base / White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.add-btn-icon {
    width: 18px;
    height: 18px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}
.title-div.social-connection{
    flex-direction: column !important;
    align-items: flex-end !important;
}
.title-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
}

.filter-div , .div-filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 24px;
    margin: 24px auto;
    width: 100%;
    gap:5px;
    background: rgba(245, 131, 31, 0.1);
    border-radius: 12px;
}
// .filter-div div {
//     flex-basis: calc(( 100% - 39px ) / 4);
//     // width: 24%;
//     // font-size: 10px;
// }

.filter-div div {
    flex-basis: calc(( 100% - 39px ) / 4);
    // width: 24%;
    // font-size: 10px;
}

.react-daterange-picker__inputGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.div-filter div {
    flex-basis: calc(( 100% - 39px ) / 3);
}

@media screen and (max-width:1200px) {
    .filter-div>div , .div-filter>div{
        flex-basis: 100%
    }
    button {
        margin-left: auto;
    }
}

.search-input-div {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;



    /* Base / White */

    background: #FFFFFF;
    /* Base/Stroke */

    border: 1px solid #E1E1E1;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.search-input-icon {
    width: 18px;
    height: 18px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.search-input {
    width: 85%;
    height: 18px;
    border: none;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.search-date-div {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
    font-size: 16px;
    /* Base / White */

    background: #FFFFFF;
    /* Base/Stroke */

    border: 1px solid #E1E1E1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  
}


.search-date-div  svg{
   width:14px;
   height:14px;
}


.search-date {

    width: 85%;
    height: 18px;
    border: none;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.search-sort-div {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    

    /* Base / White */

    background: #FFFFFF;
    /* Base/Stroke */

    border: 1px solid #E1E1E1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    /* Inside auto layout */
    order: 2;

}

.new-case-title {
    width: 96%;
    height: 24px;

    /* H3 */

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.case-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    gap: 10px;

    width: 480px;
    height: 64px;

    /* Base / White */

    background: #FFFFFF;
    border-radius: 8px 8px 0px 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.modal-close-icon {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    gap: 10px;

    width: 24px;
    height: 24px;

    /* Grey / LightGrey */

    background: #F6F6F6;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.modal-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: relative;
    width: 480px;
    /*min-width: 480px;*/
    height: 233px;
}
.modal-card.connection{
    height: 100% !important;
}
.case-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 16px;
    gap: 10px;

    width: 480px;
    height: 81px;

    /* Base / White */

    background: #FFFFFF;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.case-form div  , .case-form div input {
    width:100%
}

.case-form-input {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;

    width: 448px;
    height: 50px;

    /* Base / White */

    background: #FFFFFF;
    /* Base/Stroke */

    border: 2px solid #E1E1E1;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.case-form-label {
    width: 100%;
    height: 15px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* identical to box height */


    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.case-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 16px;
    gap: 16px;

    width: 480px;
    height: 88px;

    /* Base / White */

    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.case-close-modal-btn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px;

    width: 123px;
    height: 48px;

    /* Primary/Orange */

    border: 1px solid #F5831F;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.close-modal-btn-content {
    width: 100%;
    height: 100%;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    display: flex;
    justify-content: center;
    align-items: center;

    /* Primary/Orange */

    color: #F5831F;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.confirm-modal-btn {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px;

    width: 157px;
    height: 48px;

    /* Primary/Orange */

    background: #F5831F;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.confirm-modal-btn-content {

    width: 100%;
    height: 100%;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.status-span-finished {
  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    width: 155px;
    height: 20px;

    /* Primary/Orange */

    background: #F5831F;
    border-radius: 4px;

    /* Inside auto layout */
    color: white;

    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.status-span-inprogress {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    width: 100%;
    height: 20px;
    /* Grey / DarkGrey */

    background: #76787B;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    color: white;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.status-span-no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    width: 155px;
    height: 20px;
    color: white;

    /* Grey / Disabled */

    background: #C6C6C6;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.search-select-div {
    box-sizing: border-box;
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    /* Base / White */
    background: #FFFFFF;
    /* Base/Stroke */
    border: 1px solid #E1E1E1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

}

.search-select  {
    background-color: transparent;
    width: 100%;
    height: 18px;
    border: none;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}
.search-select option{
    font: -moz-pull-down-menu;
}


.breadcrumb-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    
    width: 100%;
    height: 18px;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.breadcrumb-div.listing {
    margin-bottom: 24px;
}

.breadcrumb-item.active {
    width: fit-content;
    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Primary/Orange */

    color: #F5831F;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.breadcrumb-seperator-item {
    width: 5px;
    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Grey / DarkGrey */

    color: #76787B;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.breadcrumb-item {
    width: fit-content;
    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Grey / DarkGrey */

    color: #76787B;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}

.table-style{
    border-radius: 20px;
    padding: 0px;
    box-shadow: 0 3px 50px rgb(0 0 0 / 20%);
}

.table-style table{
   width: 1200px;
  
}

@media screen and (min-width:1200px) {
    .table-style table {
        width: 100%;
        border-collapse: unset;
        border-spacing: 0;
        
    }
   
}

@media screen and (max-width:1200px) {
    .table-style{
        overflow-x: scroll;
    }
}

@media screen and (max-width:920px) {
   
    .modal-card{
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
   
    .case-modal-header{
        width: 100%;
        gap: 0;
    }
    .case-form-input{
        width: auto;
    }
    .case-modal-footer{
        width: auto;
        justify-content: center;
    }
    .wid{
        width: 100%;
    }
}

@media screen and (max-width:576px) {
    .case-form{
        width: auto;
    }
}

.table-style>div:nth-child(2) {
    margin: 0 10px;
}
th:first-child{
    border-top-left-radius: 20px;
    padding-left: 25px;
}
th:last-child{
    border-top-right-radius: 20px;
}
.table > :not(caption) > * > *{
    padding: 0.5rem 1.3rem;
}
.date-label{
    padding-left: 14px;
    color: black;
}

.dropdown-menu .dropdown-item  a {
  display: block;
}


.table-spinner {
    margin: 0 auto;
    width: fit-content;
}

.bg-completed{
    background-color: #F5831F!important;
}
