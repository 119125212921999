$dark-grey: #76787B;
$light-grey: #919EAB ;
$orange: #F5831F;
$transparent-orange: #fff5ed ;

.search-result {

    .order-social-canvas-wrapper {
        justify-content: end;
        display: flex;

        .order-social-canvas {
            color: white;
            padding: 15px;
            margin-bottom: 10px;
        }
    }


    .breadrumb {
        font-size: 14px;
        line-height: 18px;
        display: contents;

        a {
            color: $orange;
            font-weight: 400;
        }

        span {
            color: $dark-grey;
        }
    }

    .header {
        border-radius: 10px;
        padding: 18px;

        .personal-info {
            margin-right: 35px;

            .person-img {
                border-radius: 6px;
                margin-right: 18px;
            }

            .name {
                font-size: 20px;
                font-weight: 700;
                color: black;
                line-height: 24.36px;
            }

            .age {
                font-size: 14px;
                color: $dark-grey;
                line-height: 17.05px;
            }
        }

        .actions {

            .d-content {
                display: contents;
            }

            .icon-img {
                display: inline;
                width: 28px;
            }

            .icon-img:hover {
                cursor: pointer;
            }

            .print-view {
                color: $dark-grey;
                font-size: 14px;
                font-weight: 500;
            }

            .print-view-toggle {
                transform: translateY(10px);

                .form-check-input:checked {
                    background-color: $orange;
                    border-color: $orange;
                }

                input {
                    cursor: pointer;
                }
            }
        }

        .request-details-info {
            margin-left: 0;

            .details {
                margin-top: 4px;

                .detail {
                    margin: 10px 20px 0 0;

                    .header-img-box {
                        width: 40px;
                        height: 40px;
                        background-color: $transparent-orange;
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;

                        svg {
                            width: 22.5px;
                            height: 19.5px;
                            display: initial;
                            transform: translateX(-3px);

                            path {
                                fill: $orange;
                            }
                        }
                    }

                    .info {
                        margin-left: 10px;

                        .title {
                            color: $light-grey;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;
                        }

                        .value {
                            color: black;
                            font-size: 14px;
                            line-height: 22px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .title {
                padding: 0;

                .request-details {
                    color: $dark-grey;
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 400;
                    margin-top: 5px;
                }

                .view-details {
                    font-size: 12px;
                    color: $orange;
                    line-height: 12px;
                    font-weight: 400;
                    display: flex;
                    margin-top: 8px;

                    img {
                        width: 25px;
                        height: 15px;
                    }
                }

                .view-details:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
