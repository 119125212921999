$education-grey: #76787B;
$black: #2F3151;

.education {
    color: $black;
    padding-right: 5px !important;

    .education-card {
        border-radius: 8px;
        border: 0;
        background-color: white;
        padding: 18px;
        margin-bottom: 20px !important;
    }

    .education-title {
        font-size: 14px;
        font-weight: 600;
        margin-top: -7px;
        margin-bottom: 12px;
    }

    .education-data {
        font-size: 12px;
        font-weight: 400;
        line-height: 110%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .education-data-header {
        color: $education-grey;
    }
}

