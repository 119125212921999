$dark-grey: #76787B;

.search-result {

    .relatives {
        padding-right: 5px !important;

        .relative-card {
            border-radius: 8px;
            border: 0;
            background-color: white;
            padding: 18px;
            margin-bottom: 20px;
        }

        .relative-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: black;
            display: contents;
        }

        .relative-text {
            font-size: 12px;
            color: $dark-grey;
            padding: 0;
        }

        .val {
            color: black;
        }

        .data-row {
            margin-bottom: 15px;
        }
    }
}
