
@font-face {
    font-family: 'Metropolis';
    src: url('Metropolis-Regular.woff') format('woff'),
         url('Metropolis-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('Metropolis-Thin.woff') format('woff'),
         url('Metropolis-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('Metropolis-Light.woff') format('woff'),
         url('Metropolis-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}