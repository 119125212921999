﻿$orange: #F5831F;

.header-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 333px;
    width: 1080px;
    height: 29px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.header-title-text {
    width: 90px;
    height: 29px;

    /* H2 */

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.settings-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    // height: 1000px;
    gap: 24px;
    margin-top: 50px;
    position: inherit;
}

.tabs-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 1080px;
    height: 40px;
    background: #FBFBFB;
    border-bottom: 1px solid #E1E1E1;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.nav-link:hover {
    color: #F5831F !important;
}

.tab-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 4px;

    width: 120px;
    height: 40px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-align: center;

    /* Grey / DarkGrey */

    color: #76787B;
    flex: none;
    order: 1;
    flex-grow: 0
}

.tab-item.active {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 4px;
    width: 120px;
    height: 40px;
    border-bottom: 2px solid #F5831F !important;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-align: center;

    /* Base/Black */

    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.user-info-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    height: 100%;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.hot-news-div {
    margin: 24px auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.hot-news-title-div {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 8px;
    width: 121px;
    // height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.hot-news-icon {
    height: 24px;
    width: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.hot-news-title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #76787B;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.hot-news-content-header {
    // width: 186px;
    // height: 16px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.hot-news-content-text {
    // width: 312px;
    // height: 32px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #76787B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.hot-news-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    // width: 312px;
    // height: 52px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.change-password-title {
    height: 24px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #76787B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 16px;
}

.user-security-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    margin-top: 24px;
    height: 100%;
    flex: none;
    order: 2;
    flex-grow: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.password-td {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    height: 18px;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}

.password-td-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Grey / DarkGrey */

    color: #76787B;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.password-td-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 16px;
    height: 16px;

}

.status-td.failed {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    width: 50px;
    height: 18px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */


    color: #FFFAF5;
    /* Grey / Disabled */

    background: #C6C6C6;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.status-td.pending {
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    width: 61px;
    height: 18px;

    /* Base/Black */

    background: #000000;
    border-radius: 4px;

    /* Inside auto layout */
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */


    color: #FFFAF5;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.status-td.verified {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */


    color: #FFFAF5;


    width: 59px;
    height: 18px;

    /* Primary/Orange */

    background: #F5831F;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.modal-select-input{
    width: 100%;
}

.css-1okebmr-indicatorSeparator , .css-109onse-indicatorSeparator{
    display: none !important;
}


.css-jzldcf-Input,.css-6j8wv5-Input {
    margin: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important; 
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
}



#caseId {
    border: none !important;
    height: 59px !important;

}

.css-1insrsq-control,.css-1s2u09g-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 8px !important;
    border-style: solid;
    border-width: 1px;
    cursor: pointer !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    height: 59px !important;
}

.case-selection {
    display: flex;
    height: 50px;

    input {
        width: 22px !important;
        height: 22px !important;
        margin: 0 5px;
    }

    label {
        margin-right: 90px;
    }

    input:hover, label:hover {
        cursor: pointer;
    }

    input[type='radio']:after {
        content: "O";
        color: white;
        transform: translate(0.7px, 1.3px);
        margin-top: -10px;
        display: block;
        font-size: 25px;
        font-weight: bold;
    }
}

#contact-form {
    .email-p {
        font-size: 18px;
        color: black;
        font-weight: 400;
    }

    .input-group-meta {
        margin-bottom: 35px;
    }

    .header-section {
        padding-left: 25px;
        padding-top: 20px;
        padding-bottom: 10px;

        .section-title {
            font-size: 18px;
            font-weight: 400;
        }
    }
}

.permissions-class {
    .intro {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: black;
        margin: 25px 0 35px 0;
    }

    #your_email {
        width: 445px;
        width: 395px;
    }

    .remove-extra-input-div {
        margin-bottom: 40px;
    }

    .next-btn {
        right: 300px;
        position: absolute;
    }

    .search-input-icon {
        cursor: pointer;
    }

    table {
        margin-top: 100px;
    }

    .view-permission-title {
        color: black;
        font-weight: bolder;
    }

    label {
        font-size: 16px;
        color: gray !important;
    }
}

.glba-dppa-change-request {
    .btn {
        position: absolute;
        right: 85px;
    }

    label {
        cursor: pointer;
    }
}

.glpa-dppa-change-request-modal-card {
    width: 100%;
    height: 100%;
    top: 295px;
    background-color: white;
    opacity: 0.7;
    left: 0;
    position: absolute;
    height: 880px;
}

.glpa-dppa-change-request-modal-body {
    color: black;
    position: fixed;
    top: 490px;
    background-color: white;
    height: 200px;
    width: 960px;
    box-shadow: 0 3px 50px rgba(0, 0, 0, 0.2);
    border-radius: 17px;
    align-content: center;
    display: grid;
    left: 330px;

    .modal-intro {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
    }

    .modal-info {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
    }
}

.glpa-dppa-change-request-modal-body {
    color: black;
    position: fixed;
    top: 490px;
    background-color: white;
    height: 200px;
    width: 960px;
    box-shadow: 0 3px 50px rgba(0, 0, 0, 0.2);
    border-radius: 17px;
    align-content: center;
    display: grid;
    left: 330px;

    .modal-intro {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
    }

    .modal-info {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
    }
}

.can-not-create-search-request-modal-card {
    width: 100%;
    height: 100%;
    top: 0;
    background-color: white;
    opacity: 0.7;
    left: 0;
    position: absolute;
}

.change-glba-dppa-btn{
    text-decoration: underline;
}

btn.disabled, .btn:disabled {
    pointer-events: all;
    cursor: not-allowed;
}

.user-info-div {
    .invalid-feedback {
        margin-bottom: -22px;
    }
}

.communication {
    .btn{
        margin-right: 180px;
        }

    .email-toggle {
        transform: translate(10px, 5px);

        .form-check-input:checked {
            background-color: $orange;
            border-color: $orange;
        }

        input {
            cursor: pointer;
        }
    }
}

.communication{
    color: black;
}

.new-version-modal {
    color: black;
    font-size: 14px;
    line-height: 30px;

    .modal-btn-row {
        margin-right: 0;
    }

    .close-modal-icon {
        right: 10px;
    }

    .version-modal-header {
        margin-bottom: 10px;
    }
}

.version-last-date{
    color: black;
    margin-top: 30px;
    font-size: 16px;
}

.tos-pp-version{

    margin-top: 25px;

    .date-title{
        color: black;
        font-weight: normal;
        font-size: 14px;
    }
}

.modal-content {
    .close-modal-icon{
        margin-right: 10px;
    }
}

.permission-modal-card {
    padding: 10px 20px 20px 20px;
    width: 900px;

    .btn {
        right: 20px;
    }

    .invalid-feedback {
        position: absolute;
        transform: translate(0px, 34px);
    }
}

.tracking-wrapper {
    .request-manager-access {
        margin-bottom: -60px;
    }
}

.authorized-manager {
    align-items: center;

    .auth-manager-txt {
        color: black !important;
        font-weight: normal;
    }
}

.user-info-div {
    input[type=checkbox]:after {
        content: "✓";
        color: white;
        margin-top: -10px;
        display: block;
        font-size: 14px;
        font-weight: bold;
        transform: translate(2.25px, 19.1px);
        rotate: 3deg;
    }
}

.d-ruby{
    display: ruby;
}

.request-access-modal {
    .modal-dialog{
        display: table;
    }
}

.authorized-manager-request {
    cursor: pointer;
    margin-left: 5px;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.authorized-manager-request-btn-row {
    transform: translate(115px, -4px);
}

