.pdf-container{
    visibility: hidden;
    width: 1350px;
}
.search-result.pdf {
    border: 3 px;
    border-style: solid;
    border-color: black;
    padding: 30px;
    margin: 30px;

    img {
        vertical-align: baseline;
    }

    .google-map {
        align-self: center;
    }

    .emails{
        .title{
            line-height: inherit;
        }

        .breaches-title {
            line-height: inherit;
        }
        img {
            vertical-align: middle;
        }
    }

    .social-results {

        img {
            vertical-align: middle;
        }
        .Facebook {
            background-color: rgb(231, 237, 252);
            color: rgba(22, 80, 229, 1);
        }

        .Facebook-Gaming {
            background-color: rgb(231, 237, 252);
            color: rgba(22, 80, 229, 1);
        }

        .Twitter {
            background-color: rgb(232, 245, 254);
            color: rgba(29, 161, 241, 1);
        }

        .Instagram {
            background-color: rgb(249, 235, 246);
            color: rgba(235, 74, 103, 1);
        }

        .Pinterest {
            background-color: rgb(248, 229, 231);
            color: rgba(190, 3, 22, 1);
        }

        .SoundCloud {
            background-color: rgb(254, 243, 232);
            color: rgba(247, 135, 32, 1);
        }
    
        .Paypal {
            background-color: rgb(212, 233, 243);
            color: rgba(22, 80, 229, 1);
        }
    
        .AskFM {
            background-color: rgb(253, 231, 236);
            color: rgba(238, 17, 68, 1);
        }
    
        .Youtube {
            background-color: rgba(255, 229, 229);
            color: rgba(255, 0, 0, 1);
        }
    }

}