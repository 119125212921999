$dark-grey: #76787B;

.search-result {

    .searches {
        .search-card {
            border-radius: 8px;
            border: 0;
            background-color: white;
            padding: 18px 30px;
            margin-bottom: 20px;
            width: 100%;
        }

        .val {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            width: 89%;
            color: black;
            padding: 0 0 6px 6px;
        }

        .title {
            font-size: 12px;
            line-height: 18px;
            color: $dark-grey;
            padding: 0;
            font-weight: 400;
        }

        .data-row {
            margin-bottom: 15px;
        }

        .url {
            overflow-wrap: break-word;
            width: 86%;

            a {
                display: inline;
            }
        }

        hr {
            margin-top: 10px;
        }
    }
}
