$dark-grey: #76787B;
$orange: #F5831F;

.phones {
    font-size: 14px;
    color: #2F3151;
    font-weight: 500;

    .phone-card {
        padding: 16px;
        margin: 0 6px 25px 0;
        border-radius: 8px;
    }

    .title {
        color: $dark-grey;
        font-weight: 400;
        font-size: 12px;
        align-self: center;
    }

    .view {
        color: $orange;
        text-decoration: underline;
    }

    img {
        display: inline;
    }

    .google-search {
        justify-content: end;
        display: flex;
        align-items: center;
    }
}
