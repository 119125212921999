.search-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 333px;

    width: 712px;
    height: 29px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.search-title-text {
    width: 100%;
    height: 29px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    /* Base/Black */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.search-divider {
    height: 2px !important;
}

.form-select{
    padding: 0px !important;
    border: 1px solid rgba(0, 40, 78, 0.15) !important;
    border-radius: 8px !important;
    height: 100%;
}


.search-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    position: initial;
    width: 100%;
    height: 65px;
    left: 0px;
    top: 58px;

    /* Grey / BG */

    background: #FBFBFB;
}

.create-search-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    position: initial;
    left: 180px;
    top: 153px;
}

.select-case-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 346px;
    height: 113px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.section-title {
    height: 24px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #76787B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.section-div {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 16px;
    height: fit-content;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.extra-input-add-div {
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 147px;
    height: 16px;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0
}

.extra-input-add-icon {

    width: 16px;
    height: 16px;
    cursor: pointer;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.extra-input-add-text {
    font-size: 16px;
    height: 15px;
    cursor: pointer;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* identical to box height */


    /* Primary/Orange */

    color: #F5831F;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.remove-extra-input-div {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: 50px;
    /* background: #FFFFFF; */
    /* border: 1px solid #E1E1E1; */
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.model-title {
    font-family: 'Proxima Nova';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;

/* Base/Black */

color: #000000;
}
.title, .search-detail-title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    margin-bottom: -2px;
}

.search-detail-title{
    font-size: 14px;
}

.title-info {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    /* Grey / DarkGrey */

    color: #76787B;
}




.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../../../public/images/close.svg") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    width: 36px;
  height: 18px;

font-family: 'Proxima Nova';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */


color: #FFFFFF;
}

.loader{
    position: fixed;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }

.agreement {
    .modal-dialog {

        .modal-content {
            width: 480px;
        }

        .accept-btn-wrapper {
            text-align: center;
        }

        .btn {
            width: 170px;
            margin-top: 40px;
        }

        .modal-body{
            padding: 25px;
        }
    }

    @media (min-width: 992px) {
        .modal-lg, .modal-xl {
            max-width: max-content;
        }
    }
}

.modal-backdrop:has(+ .agreement){
    opacity: 0.1;
}

.agreement-content {
    .agreement-text {
        font-size: 14px;
        color: black;
        line-height: 30px;
        margin-top: 20px;
    }

    .agreement-title {
        color: black;
        font-weight: bolder;
    }
}

.searc-details-modal {

    .close-modal-icon {
        margin-right: 20px;
    }

    .btn-close:hover {
        opacity: 1;
    }

    .model-title {
        margin-bottom: 30px;
    }

    .search-details-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #76787B;
        margin-top: 10px;
    }
}

#react-select-2-placeholder, #dateOfLoss, #dateOfBirth {
    font-size: 16px;
    color: gray;
}

.disabled-result-icon {
    opacity: 0.5;   
}

.disabled-result-icon:hover {
    cursor: not-allowed;
}

.search-form-btn-row {
    justify-content: end;

    button {
        width: 160px;
        height: auto;
    }

    .run-form-only {
        background-color: #DA1E28;
    }
}

.correct-symbol {
    color: #29c429;
    font-size: 18px;
}

.wrong-symbol{
    color: red;
}

.bi-trash::before {
    cursor: pointer;
}

.invalid-form-data {
    position: relative;
    display: block;
    text-align: left;
    margin-top: 0;
    left: 0;
    bottom: -5px;
    font-size: 14px;
    line-height: 22px;
    padding: 0 15px;
    border-radius: 3px;
    color: rgba(220, 53, 69, 0.7490196078);
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 20px;
    font-weight: bold;
}

invalid-form-control {
    border: 1px solid rgb(220 53 69 / 75%);
}
