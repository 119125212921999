$orange: #F5831F;
$dark-grey: #76787B;
$disabled-grey: #C6C6C6 ;
$transparent-orange: #fff5ed;

.search-result {

    div[role="tabpanel"] {
        margin-left: -20px;
        width: calc(100% + 25px);
    }

    .result-count hr {
        width: 99%;
        margin-top: 20px;
    }

    hr {
        height: 0.5px;
    }

    .not-available {
        color: $disabled-grey;
        font-size: 12px;
        line-height: 14px;
        align-self: center;
    }

    .img-box {
        width: 30px;
        height: 28px;
        background-color: $transparent-orange;
        border-radius: 4px;
        margin-right: 10px;

        svg {
            width: 18px;
            height: 18px;
            transform: translate3d(-5px, -8px, 0);

            path {
                fill: $orange;
            }
        }
    }

    .img-box-clear {
        width: 30px;
        height: 28px;
        border-radius: 4px;
        margin-right: 10px;
        transform: translate(0px, -3px);

        svg {
            width: 18px;
            height: 18px;
            transform: translate3d(-5px, -8px, 0);

            path {
                fill: $orange;
            }
        }
    }

    .header-component {
        margin-bottom: 18px;
    }

    a {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $dark-grey;
        padding: 16px 0;
        width: fit-content;

        svg {
            margin-right: 8px;
        }
    }

    a:hover {
        path {
            fill: $orange;
        }
    }

    a.active {
        color: $orange !important;
        background-color: white !important;
        border-bottom: $orange solid 2px !important;
        border-radius: 0;

        path {
            fill: $orange;
        }
    }

    .nav-item {
        justify-content: center;
        display: flex;
    }

    .nav-pills {
        padding-right: 0;
        z-index: 3;
    }

    .nav-row {
        border-radius: 10px;
        justify-content: space-between;
    }

    .box-shadow {
        box-shadow: 0px 10px 20px rgba(0, 32, 145, 0.05);
    }

    .result-count {
        line-height: 27px;
        font-size: 20px;

        .count {
            color: $orange;
            letter-spacing: 2px;
        }
    }

    .result-header{
        .row{
            --bs-gutter-x: 0;
        }
    }

    .add-user-profile-btn {
        background-color: #F5831F;
        color: white;
        border-radius: 6px;
        font-size: 16px;
        width: 145px;
        height: 40px;
        display: inline-flex;
        align-items: center;
    }

    

    .details-img {
        display: initial;
    }

    .view-details-title {
        display: none;
    }

    .slide-up-btn {
        position: fixed;
        bottom: 70px;
        right: 110px;
        width: 50px;
    }

    .header-component.scroll-view {
        position: sticky;
        z-index: 3;

        .age, .icon-imgs, .details, .request-details, .print-view {
            display: none;
        }

        .personal-info .name {
            transform: translate(-6px, 12px);
            font-size: 16px;
            line-height: 16px;
        }

        .person-img {
            width: 40px;
            height: 40px;
        }

        .request-details-info .title {
            display: flex;
            justify-content: end;
        }

        .view-details-title {
            display: initial;
        }

        .header {
            padding: 10px;
            box-shadow: none;
            border-radius: 0;
            background-color: white !important;
        }

        .view-details {
            margin-top: 15px !important;
        }
    }
}

.scroll-view-background {
    width: 100%;
    position: sticky;
    z-index: 2;
    box-shadow: 0px 10px 24px -4px #919EAB1F;
    background-color: white;
}

@media screen and (max-width: 1200px) {
    .scroll-view-background {
        height: 191px;
        top: 60px;
    }

    .header-component.scroll-view {
        top: 22px;
    }

    .nav-pills {
        top: 140px;
        position: sticky;
    }

    .printable-view {
        height: 82px;
    }
}

@media screen and (min-width: 1200px) {
    .scroll-view-background {
        height: 127px;
        top: 70px;
    }

    .header-component.scroll-view {
        top: 22px;
    }

    .nav-pills {
        top: 140px;
        position: sticky;
    }

    .printable-view {
        height: 71px;
    }
}

@media screen and (max-width: 992px) {
    .scroll-view-background {
        height: 169px;
        top: 60px;
    }

    .header-component.scroll-view {
        top: 1px;
    }

    .nav-pills {
        top: 118px;
        position: sticky;
    }

    .printable-view {
        height: 60px;
    }
}

@media screen and (max-width: 768px){
    .nav-pills{
        position: initial;
    }
}

.printable-view-section {

    .addresses {
        padding: 0px;
        margin-left: -6px;
    }

    .social-results {
        padding: 10px;
        margin-left: -14px;
    }

    .searches {
        padding: 0 !important;
        margin-left: -10px;
    }

    .emails {
        padding: 7px 18px 7px 7px !important;
        margin-left: -12px;
    }
}

.nav-item-span{
    letter-spacing: 2px;
}

.user-social-profile-add-modal{
    .add-user-profile-btn {
        background-color: #F5831F;
        color: white;
        border-radius: 6px;
        font-size: 16px;
        width: 145px;
        height: 40px;
        align-items: center;
    }
}


